import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
// import Keycloak from "keycloak-js";

// TODO: temp check to skip keycloak integration if needed
// if (!process.env.REACT_APP_KEYCLOAK) {
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
// } else {
//   let initOptions = {
//     url: process.env.REACT_APP_KEYCLOAK_AUTH + "",
//     realm: process.env.REACT_APP_KEYCLOAK_REALM + "",
//     clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID + "",
//     onLoad: "login-required",
//     "enable-cors": true,
//   };

//   let keycloak = Keycloak(initOptions);

//   keycloak
//     .init({ onLoad: "login-required" })
//     .success((auth: boolean) => {
//       if (!auth) {
//         window.location.reload();
//       } else {
//         console.info("Authenticated");
//       }

//       //React Render
//       // ReactDOM.render(<App />, document.getElementById("root"));

//       ReactDOM.render(
//         <React.StrictMode>
//           <App />
//         </React.StrictMode>,
//         document.getElementById("root")
//       );

//       console.log("User Groups: ", keycloak.tokenParsed);

//       keycloak.loadUserProfile().then((profile) => {
//         console.log("user Profile: ", profile);
//       });

//       localStorage.setItem("react-token", keycloak.token || "");
//       localStorage.setItem("react-refresh-token", keycloak.refreshToken || "");

//       setTimeout(() => {
//         keycloak
//           .updateToken(70)
//           .success((refreshed) => {
//             if (refreshed) {
//               console.debug("Token refreshed" + refreshed);
//             } else {
//               console.warn(
//                 "Token not refreshed, valid for " +
//                   // Math.round(
//                   //   keycloak.tokenParsed.exp +
//                   //     keycloak.timeSkew -
//                   //     new Date().getTime() / 1000
//                   // ) +
//                   " seconds"
//               );
//             }
//           })
//           .error(() => {
//             console.error("Failed to refresh token");
//           });
//       }, 60000);
//     })
//     .error(() => {
//       console.error("Authenticated Failed");
//     });
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
