import React, { Suspense } from "react";
import DxoLoader from "./components/common/DxoLoader";

const DxoApp = React.lazy(() => import("./DxoApp"));

const App: React.FC = () => {
  return (
    <Suspense fallback={<DxoLoader />}>
      <DxoApp />
    </Suspense>
  );
};

export default App;
