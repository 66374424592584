import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { DxoCommonCompProps } from ".";

type DxoLoaderProps = { msg?: string } & DxoCommonCompProps;

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: ${(props: DxoLoaderProps) =>
    props.dxoCommonProps?.nested ? undefined : "100%"};
  width: ${(props: DxoLoaderProps) =>
    props.dxoCommonProps?.nested ? undefined : "100%"};
  justify-content: center;
  align-items: center;
`;

const DxoLoader: React.FC<DxoLoaderProps> = (props) => {
  const { msg } = props;
  return (
    <StyledMainDiv {...props}>
      <Loader />
      {msg && <Typography> {msg} </Typography>}
    </StyledMainDiv>
  );
};

export default DxoLoader;

const Loader = React.memo(styled(({ className }) => (
  <div className={className}>
    <span className="loader__ball loader__ball--1" />
    <span className="loader__ball loader__ball--2" />
    <span className="loader__ball loader__ball--3" />
  </div>
))`
  display: flex;
  /* position: absolute; */
  width: 100%;
  height: 0.75rem;
  justify-content: center;
  align-items: center;

  span.loader__ball {
    display: inline-block;
    margin: auto 0.25rem;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 0.375rem;
    background: #06c;

    &.loader__ball--1,
    &.loader__ball--2,
    &.loader__ball--3 {
      animation: bulging 2s infinite ease-in-out;
    }

    &.loader__ball--1 {
      animation-delay: -0.4s;
    }

    &.loader__ball--2 {
      animation-delay: -0.2s;
    }

    @keyframes bulging {
      0%,
      80%,
      100% {
        transform: scale(0);
        opacity: 0.5;
      }
      40% {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
`);
